import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertTextDialog } from '../alert-text-dialog/alert-text-dialog.component';
import { SelectOption } from 'src/app/settings/settings.model';

@Component({
    templateUrl: './select-dialog.component.html',
})
export class SelectDialog implements OnInit {
    options: SelectOption[];
    form = new FormGroup({
        selected: new FormControl<string | number>(null, Validators.required),
    });

    get selected() {
        return this.form.get('selected').value;
    }

    constructor(
        public dialogRef: MatDialogRef<AlertTextDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            description?: string;
            options: SelectOption[];
            value: string | number;
        }
    ) {}

    ngOnInit() {
        this.options = this.data.options;
        if (this.data.value !== null) {
            this.form.get('selected').setValue(this.data.value);
        }
    }
}
